import { createSlice } from "@reduxjs/toolkit";

const savedDealerSlice = createSlice({
  name: "savedDealer",
  initialState: {
    prefix: "",
    dealerFirstName: "",
    dealerLastName: "",
    dealerEmail: "",
    dealerContactNumber: "",
    roleId: "",
    isAdmin: "",
    isBulkUploadMenu: "",
    masterEmail: "",
    parentEmail: "",
    productName: "",
    make: "",
    model: "",
    gstNo: "",
    panNo: "",
    riskDate: "",
    dealerId: null,
  },

  reducers: {
    setSavedDealerFormDetails: (state, action) => {
      state.prefix = action?.payload?.prefix;
      state.dealerFirstName = action?.payload?.dealerFirstName;
      state.dealerLastName = action?.payload?.dealerLastName;
      state.dealerEmail = action?.payload?.dealerEmail;
      state.dealerContactNumber = action?.payload?.dealerContactNumber;
      state.roleId = action?.payload?.roleId;
      state.isAdmin = action?.payload?.isAdmin;
      state.isBulkUploadMenu = action?.payload?.isBulkUploadMenu;
      state.masterEmail = action?.payload?.masterEmail;
      state.parentEmail = action?.payload?.parentEmail;
      state.productName = action?.payload?.productName;
      state.make = action?.payload?.make;
      state.model = action?.payload?.model;
      state.gstNo = action?.payload?.gstNo;
      state.panNo = action?.payload?.panNo;
      state.riskDate = action?.payload?.riskDate;
      state.dealerId = action?.payload?.dealerId;
    },
    clearSavedDealerFormDetails: (state) => {
      state.prefix = "";
      state.dealerFirstName = "";
      state.dealerLastName = "";
      state.dealerEmail = "";
      state.dealerContactNumber = "";
      state.roleId = "";
      state.isAdmin = "";
      state.isBulkUploadMenu = "";
      state.masterEmail = "";
      state.parentEmail = "";
      state.productName = "";
      state.make = "";
      state.model = "";
      state.gstNo = "";
      state.panNo = "";
      state.riskDate = "";
      state.dealerId = "";
    },
  },
});

export const { setSavedDealerFormDetails, clearSavedDealerFormDetails } =
  savedDealerSlice.actions;
export default savedDealerSlice.reducer;
