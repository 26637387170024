import {
  Box,
  Container,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import FooterLogo from "../../component/FooterLogo";
import { Link as RouterLink } from "react-router-dom";

import { GST_NUMBER } from "../../constants";
import SVG_TWITTER from "../../assets/svg/Twitter.svg";
import SVG_FACEBOOK from "../../assets/svg/Facebook.svg";
import SVG_INSTAGRAM from "../../assets/svg/Instagram.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    padding: "40px 0px 20px",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& .vectorImage": {
      position: "absolute",
      right: "9%",
      top: "5%",
      width: "100%",
      maxWidth: "250px",
    },
    "& .mainBox": {
      position: "relative",
      zIndex: "999",
      "& .IconsBox": {
        padding: "15px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .footerLinkPages": {
        display: "flex",
        // alignItems: "center",
        "& p": {
          fontFamily: "Good Times W00 Bold",
        },
        justifyContent: "space-between",
        position: "relative",
        flexWrap: "wrap",
        paddingBottom: "30px",
        // borderBottom: "1px solid #323232",
        "& h4": {
          padding: "15px 0px",
          fontSize: "12px",
          display: "flex",
          alignItems: "center",
          "& span": {
            cursor: "pointer",
            display: "flex",
            width: "max-content",
          },
        },
      },
    },
  },
  logoDrawer2: {
    paddingLeft: "10px",
    width: "300px",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
  IconContainer: {
    // width: 60,
    // height: 60,
    width: 30,
    height: 30,
    borderRadius: 30,
  },
  hoverText: {
    color: "#000",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();

  // const openPrivacyPlicyPdf = () => {
  //   window.open("/assets/Policy.pdf", "_blank");
  // };

  const openTnCPdf = () => {
    window.open("/assets/TnC.pdf", "_blank");
  };

  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Box className="mainBox">
            {/* ****** Quick Link Container ****** */}
            <Box style={{ borderBottom: "1px solid #323232" }}>
              <Box className="footerLinkPages">
                <Box align="center">
                  <RouterLink to="/">
                    <FooterLogo className={classes.logoDrawer2} />
                  </RouterLink>
                </Box>

                <Box
                  mx={2}
                  lg={4}
                  // style={{ width: "30%" }}
                >
                  <Typography variant="h6" style={{ color: "#000" }}>
                    Quick Links
                  </Typography>

                  <Typography
                    variant="body1"
                    className={classes.hoverText}
                    onClick={() => history.push("/aboutUs")}
                  >
                    • About
                  </Typography>

                  <Typography
                    variant="body1"
                    className={classes.hoverText}
                    onClick={() => history.push("/contactUs")}
                  >
                    • Contact US
                  </Typography>

                  <Typography
                    variant="body1"
                    className={classes.hoverText}
                    onClick={() => history.push("/privacyPolicy")}
                  >
                    • Privacy Policy
                  </Typography>

                  <Typography
                    variant="body1"
                    className={classes.hoverText}
                    // onClick={() => history.push("/termConditions")}
                    onClick={() => openTnCPdf()}
                  >
                    • Terms and Conditions
                  </Typography>

                  <Typography
                    variant="body1"
                    className={classes.hoverText}
                    onClick={() => history.push("/services")}
                  >
                    • Products / Services
                  </Typography>
                </Box>

                <Box mx={2} lg={4} style={{ width: "30%" }}>
                  <Typography variant="h6" style={{ color: "#000" }}>
                    Address
                  </Typography>
                  <Typography variant="body1" style={{ color: "#000" }}>
                    Sadar Bazar Police Station, 204A <br />
                    Part 3, Bunglow Area Sadar, Meerut <br />
                    Cantt Residential Township, Meerut <br />
                    Uttar Pradesh - 25000
                  </Typography>
                </Box>
              </Box>

              {/* ******* GST Number Container ****** */}
              <Box className="IconsBox">
                <Typography variant="h6" style={{ color: "#000" }}>
                  GTSIN/UIN : {GST_NUMBER}
                </Typography>
              </Box>
            </Box>

            {/* ****** Follow US Container ****** */}
            <Box className="IconsBox">
              <Typography variant="h6" style={{ color: "#000" }}>
                Follow Us On :
              </Typography>

              <IconButton
                target="_blank"
                className={classes.IconContainer}
                href="https://www.facebook.com/"
              >
                <img alt="Logo" src={SVG_FACEBOOK} style={{ width: 60 }} />
              </IconButton>

              <IconButton
                target="_blank"
                className={classes.IconContainer}
                href="https://twitter.com/"
              >
                <img alt="Logo" src={SVG_TWITTER} style={{ width: 60 }} />
              </IconButton>

              <IconButton
                target="_blank"
                href="https://www.instagram.com/accounts/login/?hl=en"
                className={classes.IconContainer}
              >
                <img alt="Logo" src={SVG_INSTAGRAM} style={{ width: 60 }} />
              </IconButton>
            </Box>

            {/* ****** Copy Right Container ****** */}
            <Box align="center">
              <Typography variant="h6" style={{ color: "#000" }}>
                Copyright © 2023 Bharat Oday. All rights reserved.
              </Typography>
            </Box>
          </Box>
        </Container>

        <img
          alt="Footer"
          className="vectorImage"
          src="images/FooterVector.png"
        />
      </Box>
    </>
  );
}
