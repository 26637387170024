import { createSlice } from "@reduxjs/toolkit";

const createDealerSlice = createSlice({
  name: "createDealer",
  initialState: {
    adminMakeList: [],
    adminModelList: [],
  },

  reducers: {
    setAdminMakeList: (state, action) => {
      state.adminMakeList = action?.payload;
    },
    setAdminModelList: (state, action) => {
      state.adminModelList = action?.payload;
    },
    clearNewDealerFormDetails: (state) => {
      state.adminMakeList = [];
      state.adminModelList = [];
    },
  },
});

export const {
  setAdminMakeList,
  setAdminModelList,
  clearNewDealerFormDetails,
} = createDealerSlice.actions;
export default createDealerSlice.reducer;
