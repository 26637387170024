//  ********** Original Running Code **********
import { Provider, useSelector } from "react-redux";
import MomentUtils from "@date-io/moment";
import { createBrowserHistory } from "history";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@material-ui/core";
import { Router, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { Suspense, useContext, Fragment, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";

import { routes } from "src/routes";
import { createTheming } from "src/theme";
import AuthContext from "src/context/Auth";
import AuthGuard from "src/component/AuthGuard";
import { store, persistor } from "./redux/store"; // Import the Redux store
import PageLoading from "src/component/PageLoading";
import SettingsContext from "src/context/SettingsContext";
import { PersistGate } from "redux-persist/integration/react";

const history = createBrowserHistory();

function App() {
  const themeSeeting = useContext(SettingsContext);
  const theme = createTheming({
    theme: themeSeeting.settings.theme,
  });

  useEffect(() => {
    const handleCopyCut = (e) => {
      e.preventDefault(); // Prevent the action
      // alert("Copying and cutting content are not allowed.");
    };

    document.addEventListener("copy", handleCopyCut);
    document.addEventListener("cut", handleCopyCut);

    return () => {
      document.removeEventListener("copy", handleCopyCut);
      document.removeEventListener("cut", handleCopyCut);
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <AuthContext>
                <Router history={history}>
                  <RenderRoutes data={routes} />
                  <ToastContainer position="top-right" />
                </Router>
              </AuthContext>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;

function RenderRoutes(props) {
  const reduxData = useSelector((state) => state?.auth?.type);
  console.log("29 ==== reduxData ====", reduxData);

  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {props.data.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          const Layout = route.layout || Fragment;
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} />
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}
