import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "./layouts/LoginLayout/inedx";

export const routes = [
  // Home Layout
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("./views/user/pages/Home")),
  },

  // About US
  {
    exact: true,
    path: "/aboutUs",
    layout: HomeLayout,
    component: lazy(() => import("./views/user/aboutUs")),
  },

  // Contact US
  {
    exact: true,
    path: "/contactUs",
    layout: HomeLayout,
    component: lazy(() => import("./views/user/contactUs")),
  },

  // Privacy Policy
  {
    exact: true,
    path: "/privacyPolicy",
    layout: HomeLayout,
    component: lazy(() => import("./views/user/privacyPolicy")),
  },

  // Terms and Condition
  {
    exact: true,
    path: "/termConditions",
    layout: HomeLayout,
    component: lazy(() => import("./views/user/termConditions")),
  },

  // Services
  {
    exact: true,
    path: "/services",
    layout: HomeLayout,
    component: lazy(() => import("./views/user/services")),
  },

  // Create Policy
  {
    exact: true,
    path: "/createPolicy",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/user/pages/insurance/createPolicy/createPolicy")
    ),
  },

  // View Policy
  {
    exact: true,
    path: "/viewPolicy",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/user/pages/insurance/viewPolicy/viewPolicy")
    ),
  },

  // Edit Policy
  {
    exact: true,
    path: "/editPolicy",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/user/pages/insurance/editPolicy/editPolicy")
    ),
  },

  // Add Balance
  {
    exact: true,
    path: "/addBalance",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/user/pages/addBalance/addBalance")),
  },

  // Change Password
  {
    exact: true,
    path: "/changePassword",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/user/pages/auth/changepassword")),
  },

  // Dealer MIS
  {
    exact: true,
    path: "/dealerMis",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/user/pages/dealerMis/dealerMis")),
  },

  // Preview Policy
  {
    exact: true,
    path: "/previewPolicy",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/user/pages/insurance/previewPolicy/previewPolicy")
    ),
  },

  // Insurance or View PDF 2
  {
    exact: true,
    path: "/view-insurance-policy",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/user/pages/insurance/Ops/viewInsurance")
    ),
  },

  // Assurance or View PDF 1
  {
    exact: true,
    path: "/view-assurance-policy",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/user/pages/insurance/Ops/viewInsurance")
    ),
  },

  // *************** Admin Routes Starts ***************
  {
    exact: true,
    path: "/admin-dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/dashboard")),
  },
  // Create Dealer
  {
    exact: true,
    path: "/admin-create-dealer",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/dealer/createDealer")),
  },

  // View Edit Dealer
  {
    exact: true,
    path: "/admin-viewedit-dealer",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/dealer/viewEditDealer")),
  },

  // View All Dealer
  {
    exact: true,
    path: "/admin-view-all-dealer",
    layout: DashboardLayout,
    // component: lazy(() => import("./views/user/admin/dealer/viewEditDealer")),
    component: lazy(() => import("./views/user/admin/viewAllDealers")),
  },

  // Add Policy
  {
    exact: true,
    path: "/admin-view-policy",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/addPolicy")),
  },

  //  Edit Created Dealer
  {
    exact: true,
    path: "/admin-edit-created-dealerById",
    layout: DashboardLayout,
    // component: lazy(() => import("./views/user/admin/dealer/viewEditDealer")),
    component: lazy(() => import("./views/user/admin/editCreatedDealer")),
  },

  // Discount
  {
    exact: true,
    path: "/admin-discount",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/discount")),
  },

  // Edit Dealer
  {
    exact: true,
    path: "/admin-edit-dealer",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/dealer/editDealer")),
  },

  // Edit Vehicle
  {
    exact: true,
    path: "/admin-edit-vehicle",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/vehicle/editVehicle")),
  },

  // Create Vehicle
  {
    exact: true,
    path: "/admin-create-vehicle",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/vehicle/createVehicle")),
  },

  // View Edit Vehicle
  {
    exact: true,
    path: "/admin-viewedit-vehicle",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/vehicle/viewEditVehicle")),
  },

  // Report Analytics
  {
    exact: true,
    path: "/admin-report-analytics",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/reportAnalytics")),
  },

  // Create Benefits
  {
    exact: true,
    path: "/admin-create-benefits",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/createBenefits")),
  },

  // View Benefits
  {
    exact: true,
    path: "/admin-view-benefits",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/plan")),
  },

  // OPS Upload Certificate
  {
    exact: true,
    path: "/admin-add-policy",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/opsUpload")),
  },

  // Wallet
  {
    exact: true,
    path: "/admin-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/wallet")),
  },

  // Profile
  {
    exact: true,
    path: "/admin-profile",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/profile")),
  },

  // Edit Profile
  {
    exact: true,
    path: "/admin-edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/editProfile")),
  },

  // *************** Auth Routes ***************
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/user/pages/auth/login")),
  },

  // Forgot Password
  {
    exact: true,
    path: "/forgotPassword",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/user/pages/auth/forgotPassword/forgotPassword")
    ),
  },

  // Otp
  {
    exact: true,
    path: "/otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/user/pages/auth/otp/otp")),
  },

  // Reset
  {
    exact: true,
    path: "/reset",
    layout: LoginLayout,
    component: lazy(() => import("src/views/user/pages/auth/reset/reset")),
  },

  // Not Found
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/user/errors/NotFound")),
  },

  // Coming Soon
  {
    exact: true,
    path: "/ComingSoon",
    component: lazy(() => import("src/views/user/errors/ComingSoon")),
  },

  // 404 Page
  {
    component: () => <Redirect to="/404" />,
  },

  // Coming Soon
  {
    component: () => <Redirect to="/ComingSoon" />,
  },
];
