export const ASSETS_PATH = {
  GIF_CAR_ANIMATION: require("../assets/gif/CarAnimation.gif"),

  IMG_LOGO: require("../assets/images/Logo.png"),
  IMG_ERROR: require("../assets/images/Error.jpg"),
  IMG_CRASH: require("../assets/images/Crash.jpg"),
  IMG_PROFILE: require("../assets/images/Profile.png"),
  IMG_SPLASH_LOGO: require("../assets/images/SplashLogo.png"),
  IMG_NO_DATA_FOUND: require("../assets/images/NoDataFound.jpg"),

  SVG_TWITTER: require("../assets/svg/Twitter.svg"),
  SVG_FACEBOOK: require("../assets/svg/Facebook.svg"),
  SVG_LOCATION: require("../assets/svg/Location.svg"),
  SVG_INSTAGRAM: require("../assets/svg/Instagram.svg"),
  SVG_WEBSITE_LOGO: require("../assets/svg/WebsiteLogo.svg"),
};
