import React from "react";
// import WEBSITE_LOGO from "../assets/images/BharatTodayLogo.png";
import WEBSITE_LOGO from "../assets/images/BharatTodayLogo.svg";

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src={WEBSITE_LOGO}
      style={{ width: 250, marginBottom: -35 }}
    />
  );
};

export default Logo;
